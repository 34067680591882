import React from "react"
import loadable from "@loadable/component";
import { navigate } from "gatsby";

import ApplyInterFont from "../components/campaigns/ApplyInterFont"
import SelfServe from "../components/SelfServe"
import { LeadFormProvider } from "../context/LeadFormContext"
import GLPNavbar from "../components/campaigns/GLPNavbar";
import { useCampaignLPStates } from "../hooks/useCampaignLPStates";
import HeroSection from "../components/campaigns/HeroSection";
import { Section, SectionWrapper } from "../components/core/commonExports";
import { HeroBg, HeroSectionContainer, ModalContainer, HeroSectionData, QuoteModalPlaceholder } from "../components/campaigns/SharedComponents";
import useIsInitialMount from "../hooks/useIsInitialMount";
import { isBrowser } from "../utils/browserUtils";

const LeadForm = loadable(() => import("../components/LeadForm"));

const SelfServeFlow = ({location={}}) => {
  const {state} = location
  const isInitialMount = useIsInitialMount()
  const {
    leadformWrapperRef,
    leadformRef,
    hideHeroSection,
    fetchEstimatedPremium,
    screen,
    setScreen,
    isSelfServe,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  React.useEffect(() => {
    if (!state?.leadFormData && isInitialMount) {
      navigate('/')
    } 
    if (isBrowser) {
      // redirect to home page on browser refresh
      window.onbeforeunload = e => {
        navigate('/', {})
      }
    }
  }, [state, isInitialMount])

  if (isInitialMount && !state) return

  return (
    <>
      <ApplyInterFont />
      <GLPNavbar hideQuoteCTA={true} showCallHelplineCTA={true} handleCallScheduleClick={handleCallScheduleClick} />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} locationData={location} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff" style={{ display: isSelfServe ? "none" : "block", marginTop: '4rem', position: "relative"}}>
          <HeroBg />
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: hideHeroSection ? "0" : "6px"}} desktopStyles={{paddingTop: "40px"}}>
            <HeroSectionData>
              <HeroSectionContainer hideHeroSection={hideHeroSection}>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={null}
                />
              </HeroSectionContainer>
              <ModalContainer hideHeroSection={hideHeroSection} ref={leadformWrapperRef}>
                <LeadForm 
                  ref={leadformRef}
                  formType="google" 
                  renderAsPopup={false} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />} 
                  setHeroSectionScreen={setScreen}
                />
              </ModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
    </>
  )
}

export default SelfServeFlow